export const environment = {
  production: true,

  cities: [
    {title: 'Windhoek'},
    {title: 'Swakopmund'},
    {title: 'Walvis Bay'},
    {title: 'Okahandja'},
  ],

  categories: [
    {title: 'Business'},
    {title: 'Entertainment'},
    {title: 'Festival'},
    {title: 'Food'},
    {title: 'Lifestyle'},
    {title: 'Music'},
    {title: 'Nightlife'},
    {title: 'Sports'}
  ],

  dates: ['Today', 'Tomorrow', 'This Week', 'This Month', 'This Year'],

  banks: ['Bank Windhoek', 'First National Bank', 'Standard Bank', 'Nedbank'],

  BUDDY_MERCHANT_KEY: 'd1e5ebcff1',
  BUDDY_API: 'https://buddy.na/api/v1/merchant-services/simple-payment',

  BUDDY_FIXED_FEE: 2,
  BUDDY_FEE_PERCENTAGE: 3,

  // API_ROOT: 'http://localhost:8082',
  API_ROOT: 'https://app.nam-events.com',

};
